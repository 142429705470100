// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-index-js": () => import("/opt/build/repo/src/components/blog/index.js" /* webpackChunkName: "component---src-components-blog-index-js" */),
  "component---src-components-blog-post-js": () => import("/opt/build/repo/src/components/blog/post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-components-blog-categories-js": () => import("/opt/build/repo/src/components/blog/categories.js" /* webpackChunkName: "component---src-components-blog-categories-js" */),
  "component---src-components-blog-category-js": () => import("/opt/build/repo/src/components/blog/category.js" /* webpackChunkName: "component---src-components-blog-category-js" */),
  "component---src-components-blog-tags-js": () => import("/opt/build/repo/src/components/blog/tags.js" /* webpackChunkName: "component---src-components-blog-tags-js" */),
  "component---src-components-blog-tag-js": () => import("/opt/build/repo/src/components/blog/tag.js" /* webpackChunkName: "component---src-components-blog-tag-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

